'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { FC, useEffect, useMemo } from 'react';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { useAdvertisementStore } from 'modules/stores/page/advertisement/useAdvertisementStore';
import { useSegmentParams } from 'modules/segmentParams/useSegmentParams';

const DEFAULT_GEMIUS_ID = '0nUwO29Lyzs368kggazNSsVlTO4Bgayu6KWvUX37cyL.W7';
const gemiusPathToId: Record<string, string> = {
    '/realitne-kancelarie-makleri': 'ciuQ.Y8Y2ypnacjukW_1IuUC7MPRNOxtiAK.Zc57g6..g7',
    '/realitne-kancelarie':
        'ciuQ.Y8Y2ypnacjukW_1IuUC7MPRNOxtiAK.Zc57g6..g7',
    '/realitna-kancelaria':
        'ciuQ.Y8Y2ypnacjukW_1IuUC7MPRNOxtiAK.Zc57g6..g7',
    '/realitni-makleri':
        'ciuQ.Y8Y2ypnacjukW_1IuUC7MPRNOxtiAK.Zc57g6..g7',
    '/realitny-makler':
        'ciuQ.Y8Y2ypnacjukW_1IuUC7MPRNOxtiAK.Zc57g6..g7',
    '/':
        'bJE639gQp9SS.h.TrMAp88Vl7DoBgexR6IWfDH6Lm33.R7',
    '/developerske-projekty-a-novostavby':
        'zCs6GLiTp0sNurBlEPOFSceyP_ikgbsR_igkH3EaeX7.d7'
};

const sefParametersApartments: string[] = [
    'byty',
    '1-izbove-byty',
    '2-izbove-byty',
    '3-izbove-byty',
    '4-izbove-byty',
    '5-a-viac-izbove-byty',
    'apartmany',
    'lofty',
    'mezonety',
];

const sefParametersHouses: string[] = [
    'domy',
];

const sefParametersLands: string[] = [
    'pozemky',
    'pozemky-rodinne-domy',
    'rekreacne-pozemky',
    'komercne-pozemky',
    'zahrady',
    'sady-vinice-chmelnice',
    'polnohospodarske-lesne-pozemky',
];

const sefParametersRecreational: string[] = [
    'rekreacne-nehnutelnosti',
];

const sefParametersSpacesAndObjects: string[] = [
    'priestory-a-objekty',
    'garaze-parkovanie',
    'kancelarie-administrativa',
    'obchody',
    'restauracie',
    'sklady',
    'vyroba',
    'hotely-penziony',
    'ine-priestory-a-objekty',
];

const rtbHousePathToId: Record<string, string> = {
    '/': 'home',
    'detail': 'offer',
    '/vysledky': 'listing',
};

const getLivemonitorPathToEvent = (
    params: Record<string, string>
): Record<string, string[]> => {
    return {
        '/': ['HP'],
        '/realitne-kancelarie-makleri': ['adresar-agency'],
        '/realitne-kancelarie': ['adresar-agency'],
        '/realitna-kancelaria': ['detail-agency', params?.['id'] || ''],
        '/realitni-makleri': ['adresar-agency'],
        '/realitny-makler': ['detail-agency', params?.['id'] || ''],
        '/vysledky': ['Advert search'],
        '/detail': [
            params?.['gallery'] ? 'Advert-fotogallery' : 'Advert',
            params?.['id'] || '',
        ]
    };
};

interface IUrl {
    pathname: string;
    asPath: string;
    params: Record<string, string>;
}

const serializeUrl = (url: IUrl): string => {
    return JSON.stringify(url);
};

const unserializeUrl = (serializedUrl: string): IUrl => {
    return JSON.parse(serializedUrl);
};

interface IPageViewProps {
    searchParams?: Record<string, string>;
}

const PageView: FC<IPageViewProps> = ({ searchParams: propSearchParams }) => {
    const {
        facebookPixel: { pageView: facebookPixelPageView },
        gemius: { pageView: gemiusPageView },
        rtbHouse: { sendEvent: rtbHouseSendEvent },
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    const pathname = usePathname();

    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);
    const advDetailState = useAdvertisementStore((store) => store.pageViewAdvertisementDetailState);
    const listingSearchResultadvertisementIds = useAdvertisementStore((store) => store.listingAdvertisementIds);

    const hookSearchParams = useSearchParams();
    const hookSegmentParams = useSegmentParams();

    const searchParams = useMemo(() => {
        return propSearchParams
            ? propSearchParams
            : hookSearchParams
                ? {
                    ...hookSegmentParams,
                    ...Object.fromEntries(hookSearchParams)
                }
                : {};
    }, [propSearchParams, hookSearchParams, hookSegmentParams]);

    const urlSerialized = useMemo(() => {
        const pathnameDefault = pathname || '';
        return serializeUrl({
            pathname: pathnameDefault,
            asPath: pathnameDefault,
            params: searchParams,
        });
    }, [pathname, searchParams]);

    useEffect(() => {
        if (!urlSerialized) {
            return;
        }

        facebookPixelPageView();
    }, [facebookPixelPageView, urlSerialized]);

    useEffect(() => {
        if (!urlSerialized) {
            return;
        }

        const { pathname, params } = unserializeUrl(urlSerialized);
        let gemiusId = gemiusPathToId[pathname] || DEFAULT_GEMIUS_ID;

        if (pathname.startsWith('/detail')) {
            if (!advDetailState?.mainCategoryValue && !params?.['group']) {
                return;
            }

            switch (advDetailState?.mainCategoryValue) {
            case EMainCategory.APARTMENTS:
                gemiusId = 'zDsw27fDy7tisCjsnKXtp5cP.h3UNgbnzSLoaeUccM..j7';
                break;
            case EMainCategory.COTTAGES_AND_CABINS:
                gemiusId = 'csuQ_4_Y2vLNU2j0mmglxJbx7KPUKuxdo3sli5C8m9P.G7';
                break;
            case EMainCategory.LANDS:
                gemiusId = 'css6QY_Yp4tnn2awEQHN0pbxTIPUKqyizSvPGOUsA2..h7';
                break;
            case EMainCategory.HOUSES:
                gemiusId = 'cjU6P4_lp1QHirAHATSBLuTkXtDRKMdD8pL4riruSGT.f7';
                break;
            case EMainCategory.OBJECTS:
            case EMainCategory.SPACES:
                gemiusId = 'zDuan7fDt4wMhmZwnCY1y5cPTO3UNqyuA9Kv0_Avc87.c7';
                break;
            }

            if (params?.['group']) {
                gemiusId = '.X1FL6tJ4W9_As2NXbUEK6RtXovGpce112XrB1YdOMH.M7';
            }
        }

        if (pathname.startsWith('/vysledky')) {
            if (params?.['first']) {
                let isIdApplied = false;
                if (sefParametersApartments.includes(params?.['first'])) {
                    gemiusId = 'css64Y_YpwsHlGXQARdFGpbx.h3UKgbnA6VC44f3WEP.Z7';
                    isIdApplied = true;
                }

                if (sefParametersHouses.includes(params?.['first'])) {
                    gemiusId = 'zDswPbfDy3IC2o9rfNWQyZevXtDUdsdDAwwYtYgPWOH.m7';
                    isIdApplied = true;
                }

                if (sefParametersLands.includes(params?.['first'])) {
                    gemiusId = 'csua.4_YtwvN7VA7GsbhGOUC.j3RNAb3iAJii86j8Bz.C7';
                    isIdApplied = true;
                }

                if (sefParametersRecreational.includes(params?.['first'])) {
                    gemiusId = 'csuaAY_Yt0Nt52ZQwqG9guUC7KPRNOxd8hAFQ4Org1f..7';
                    isIdApplied = true;
                }

                if (sefParametersSpacesAndObjects.includes(params?.['first'])) {
                    gemiusId = 'csuaAY_Yt0Nt52ZQwqG9guUC7KPRNOxd8hAFQ4Org1f..7';
                    isIdApplied = true;
                }

                if (!isIdApplied) {
                    gemiusId = 'csuaAY_Yt0LNjWZQ0tY9guWiXkbRdMcnUgQYF.rwsDT.R7';
                }
            } else {
                gemiusId = 'csuaAY_Yt0LNjWZQ0tY9guWiXkbRdMcnUgQYF.rwsDT.R7';
            }
        }

        gemiusPageView({
            id: gemiusId,
        });
    }, [
        gemiusPageView,
        advDetailState?.mainCategoryValue,
        urlSerialized,
    ]);

    useEffect(() => {
        if (!urlSerialized) {
            return;
        }

        if (isUserLoggedIn === undefined) {
            return;
        }
        const { pathname, params } = unserializeUrl(urlSerialized);
        const eventType = rtbHousePathToId[pathname] || 'unknown';
        const pageViewEvent = {
            eventType: eventType,
        };

        if (pathname.startsWith('/detail') && params?.['id']) {
            pageViewEvent.eventType = rtbHousePathToId['detail'];
            pageViewEvent['offerId'] = params['id'];
        }

        if (pathname.startsWith('/vysledky')) {
            if (listingSearchResultadvertisementIds === null) {
                return;
            }

            pageViewEvent.eventType = rtbHousePathToId['/vysledky'];
            pageViewEvent['offerIds'] = listingSearchResultadvertisementIds;
        }

        rtbHouseSendEvent( pageViewEvent );
    }, [isUserLoggedIn, listingSearchResultadvertisementIds, rtbHouseSendEvent, urlSerialized]);

    useEffect(() => {
        if (!urlSerialized) {
            return;
        }

        const { pathname, params } = unserializeUrl(urlSerialized);

        let eventData: string[] = [];
        Object.entries(getLivemonitorPathToEvent(params)).forEach((path => {
            if (pathname.startsWith(path[0])) {
                eventData = path[1];
            }
        }));

        if (!eventData.length) {
            return;
        }

        livemonitorSendEvent({
            p: ['pageView', ...eventData],
            o: { pn: 'nehnutelnosti' }
        });
        livemonitorSendEvent({
            p: ['pageView', ...eventData],
            o: { pn: 'plt-stats' }
        });


    }, [livemonitorSendEvent, urlSerialized]);

    return null;
};

export default PageView;
